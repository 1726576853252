import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const Choice0OS = ({ onChoiceChange }) => {
  const [selectedOption, setSelectedOption] = useState("Общестрой");

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    onChoiceChange(value);
  };

  return (
    <div className="choice0OS" style={{ paddingLeft: "20px" }}>
      <RadioGroup value={selectedOption} onChange={handleOptionChange} row>
        <FormControlLabel
          value="Общестрой"
          control={<Radio />}
          label="Общестрой"
          sx={{ fontSize: "1.4rem" }}
        />
        <FormControlLabel
          value="ЖБИ"
          control={<Radio />}
          label="ЖБИ"
          sx={{ fontSize: "1.4rem" }}
        />
        <FormControlLabel
          value="ЖБИ Москва"
          control={<Radio />}
          label="ЖБИ Москва"
          sx={{ fontSize: "1.4rem" }}
        />
        <FormControlLabel
          value="Газобетон"
          control={<Radio />}
          label="Газобетон"
          sx={{ fontSize: "1.4rem" }}
        />
      </RadioGroup>
    </div>
  );
};

export default Choice0OS;
