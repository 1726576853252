import React from 'react';
import { Button } from '@mui/material';

const ResponsiveButton = ({
  color = 'blue', // Цвет кнопки
  size = 'medium', // Размер кнопки
  fullWidth = false, // Занимает всю ширину
  disabled = false, // Отключённое состояние
  margin = 'medium', // Внешние отступы: small, medium, large
  paddingEnabled = true, // Включить внутренние отступы
  marginEnabled = true, // Включить внешние отступы
  children,
  ...props
}) => {
  // Карта стилей для цветов
  const colorMap = {
    blue: {
      backgroundColor: 'var(--BLUE_BUTTON)',
      hoverColor: 'var(--BLUE_BUTTON_DARK)',
    },
    green: {
      backgroundColor: 'var(--GREEN_BUTTON)',
      hoverColor: 'var(--GREEN_BUTTON_DARK)',
    },
    red: {
      backgroundColor: 'var(--RED_BUTTON)',
      hoverColor: 'var(--RED_BUTTON_DARK)',
    },
    purple: {
      backgroundColor: 'var(--PURPLE_BUTTON)',
      hoverColor: 'var(--PURPLE_BUTTON_DARK)',
    },
  };

  // Карта стилей для отступов
  const paddingMap = {
    small: 'var(--button-padding-small)',
    medium: 'var(--button-padding-medium)',
    large: 'var(--button-padding-large)',
  };

  // Карта внешних отступов
  const marginMap = {
    small: 'var(--button-margin-small)',
    medium: 'var(--button-margin-medium)',
    large: 'var(--button-margin-large)',
  };

  const styles = colorMap[color] || colorMap.blue;

  return (
    <Button
      {...props}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{
        fontSize: size === 'small' ? '0.750rem' : size === 'large' ? '1rem' : '0.875rem',
        padding: paddingEnabled ? paddingMap[size] : 0, // Условно добавляем внутренние отступы
        margin: marginEnabled ? marginMap[margin] : 0, // Условно добавляем внешние отступы
        backgroundColor: disabled ? 'var(--DISABLED_COLOR)' : styles.backgroundColor,
        color: '#fff',
        borderRadius: 'var(--BORDER_RADIUS)',
        cursor: disabled ? 'not-allowed' : 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: disabled ? 'var(--DISABLED_COLOR)' : styles.hoverColor,
        },
      }}
    >
      {children}
    </Button>
  );
};

export default ResponsiveButton;
