/* global BX24 */
import { useEffect, useState } from "react";
import { getAddressFromBitrix24, getContactFromBitrix24 } from "../fetches";

const TestComponent = ({ onDealId, onDealAddress, onContactData }) => {
    const [dealId, setDealId] = useState(null);

    // Получение данных сделки через HTTP-запрос
    useEffect(() => {
        if (!dealId) return;

        const fetchDealId = async () => {
            console.log(`Запрос данных сделки для ID: ${dealId}...`);
            try {
                const response = await fetch("/api/bitrixtest", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ dealId, products: [] }),
                });

                if (!response.ok) {
                    throw new Error(`Ошибка сервера: ${response.statusText}`);
                }

                const data = await response.json();
                if (data.result) {
                    const extractedData = data.result.dealId;
                    //console.log("Готово. ID от сервера:", extractedData);
                    if (onDealId) onDealId(extractedData); // Передача данных родителю
                } else {
                }
            } catch (error) {
                console.error("Ошибка при получении данных сделки:", error);
            }
        };

        fetchDealId();
    }, [dealId, onDealId]);

    // Получение адреса сделки
    useEffect(() => {
        if (!dealId) return;

        const fetchDealAddress = async () => {
            try {
                const data = await getAddressFromBitrix24(dealId);
                console.log("data bitr ", data)
                const formattedCoor = data.dealData.UF_ADDRESS_COORDS.split("||")[1]; // Извлечение координат
                const coor = formattedCoor.replace(",", ", ");
                const contact_id = data.dealData.CONTACT_ID; // Извлечение координат
                console.log("Готово. адрес:", coor);
                console.log("Готово. id контакта:", contact_id);


                if (onDealAddress) onDealAddress(coor);

                // Получение данных о контакте
                const contactData = await getContactFromBitrix24(contact_id);
                console.log("Данные контакта:", contactData.contactData.NAME); // Вывод данных контакта в консоль
                if (onContactData) {
                    // Передача данных контакта в родительский компонент
                    onContactData(contactData.contactData.NAME);
                }
            } catch (error) {
                console.error("Ошибка при получении адреса сделки:", error);
            }
        };

        fetchDealAddress();
    }, [dealId, onDealAddress, onContactData]);

    // Получение ID сделки через BX24 API
    useEffect(() => {
        if (!window.BX24) {
            console.error(
                "BX24 API не доступен. Убедитесь, что приложение загружено внутри Битрикс24."
            );
            return;
        }

        console.log("Инициализация BX24...");
        BX24.init(() => {
            const placementInfo = BX24.placement.info();
            console.log("Информация о размещении:", placementInfo);

            if (placementInfo.options && placementInfo.options.ID) {
                const bxDealId = placementInfo.options.ID.toString();
                console.log("Получен ID сделки через BX24 API:", bxDealId);
                setDealId(bxDealId);
                
                BX24.fitWindow();
            
        } else {
            console.warn("Не удалось получить ID сделки из BX24 API.");
        }
    });
}, []);

    return null; // Дочерний компонент ничего не отображает, если данные передаются в родителя
};

export default TestComponent;
