let cachedData = null; // Кэшированные данные, чтобы не запрашивать их повторно
let cachedDestination = null; // Кэшированное место назначения
let cachedDistances = {}; // Кэшированные расстояния для каждого производства
let cachedKadDistance = null; // Кэшированное расстояние до КАД
let cachedCoords = null;
let globalCheapestMethod = null; // Глобальная переменная для хранения результата calculateCheapestDeliveryMethod

const { fetchDistance, fetchDataGAZ } = require("./fetches.js"); // Импорт функций для получения данных и расчета расстояний
const defaultCoord = "10.000000, 10.000000"; // Координаты по умолчанию

let cachedCombinations = null; // Кэшированные комбинации

// Фильтруем нежелательные элементы
function filterExcludedProductions(productions) {
  const excludedProductions = new Set([
    "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ",
    "Производства",
  ]);
  return productions.filter(
    (production) => !excludedProductions.has(production)
  );
}

// Генерация всех возможных комбинаций с фильтрацией нежелательных элементов
function generateAllCombinations(productions) {
  const result = new Set();
  const maxAdditionalProductions = 1; // для комбинаций от 1 до 4 производств

  function combine(acc, rest, startIndex) {
    const sortedAcc = [...acc].sort();
    result.add(JSON.stringify(sortedAcc));

    if (acc.length === maxAdditionalProductions + 1) return;

    for (let i = startIndex; i < rest.length; i++) {
      acc.push(rest[i]);
      combine(acc, rest, i + 1);
      acc.pop();
    }
  }

  combine([], productions, 0);
  return Array.from(result).map((item) => JSON.parse(item));
}

// Инициализация кэша при запуске
function initializeCombinationCache(productions) {
  if (!cachedCombinations) {
    const filteredProductions = filterExcludedProductions(productions);
    cachedCombinations = generateAllCombinations(filteredProductions);
    //console.log(`Сгенерировано ${cachedCombinations.length} комбинаций`);
  }
}

const calculateDeliveryCost = (
  productionSheet,
  production,
  deliveryMethod,
  distance,
  totalTrips, // Используем общее количество рейсов
  totalPallets
) => {
  //console.log("Входные данные для calculateDeliveryCost:");
  //console.log("productionSheet:", productionSheet);
  //console.log("production:", production);
  //console.log("deliveryMethod:", deliveryMethod);
  //console.log("distance:", distance);
  //console.log("totalTrips:", totalTrips);
  //console.log("totalPallets:", totalPallets);

  const productionRowIndex = productionSheet.findIndex(
    (row) => row[0] === production
  );

  if (productionRowIndex === -1) {
    console.warn(`Производство ${production} не найдено в productionSheet`);
    return null;
  }

  const deliveryRow = productionSheet[productionRowIndex];

  // Определяем индексы столбцов в зависимости от метода доставки
  let rateIndex, minCostIndex, deliveryCostIndex, unloadCostIndex;
  if (deliveryMethod === "Шаланда" || deliveryMethod === "Оптимальный способ") {
    [rateIndex, minCostIndex, deliveryCostIndex, unloadCostIndex] = [
      3, 4, 5, 6,
    ];
  } else if (deliveryMethod === "Манипулятор 10т") {
    [rateIndex, minCostIndex, deliveryCostIndex, unloadCostIndex] = [
      7, 8, 9, 10,
    ];
  } else if (deliveryMethod === "Манипулятор с прицепом") {
    [rateIndex, minCostIndex, deliveryCostIndex, unloadCostIndex] = [
      11, 12, 13, 14,
    ];
  } else {
    console.warn(`Неизвестный метод доставки: ${deliveryMethod}`);
    return null;
  }

  // Извлекаем данные
  const rate = parseFloat(deliveryRow[rateIndex]?.replace(/\s/g, "")) || 0;
  const minCost =
    parseFloat(deliveryRow[minCostIndex]?.replace(/\s/g, "")) || 0;
  const deliveryCost =
    parseFloat(deliveryRow[deliveryCostIndex]?.replace(/\s/g, "")) || 0;
  const unloadCost =
    parseFloat(deliveryRow[unloadCostIndex]?.replace(/\s/g, "")) || 0;

  // Вывод извлеченных данных
  //console.log("Извлеченные данные:");
  //console.log("rate:", rate);
  //console.log("minCost:", minCost);
  //console.log("deliveryCost:", deliveryCost);
  //console.log("unloadCost:", unloadCost);

  // Рассчитываем стоимость доставки
  // Рассчитываем стоимость доставки
  let totalDeliveryCost;
  if (deliveryMethod === "Шаланда" || deliveryMethod === "Оптимальный способ") {
    totalDeliveryCost = distance * rate + deliveryCost;
  } else {
    totalDeliveryCost =
      distance * rate + deliveryCost + totalPallets * unloadCost;
  }

  // Убедимся, что итоговая стоимость не меньше минимальной
  const finalCost = Math.max(totalDeliveryCost, minCost);

  console.log("Итоговая стоимость доставки:", finalCost);

  return finalCost;
};

async function getProductionDataGAZ(
  productList,
  destination,
  paymentMethod,
  deliveryMethod
) {
  try {
    console.log("все продукты", productList)
    // Проверяем, есть ли кэшированные данные. Если нет, загружаем их.
    if (!cachedData) {
      cachedData = await fetchDataGAZ();
    }

    if (!cachedData) {
      throw new Error("Ошибка: данные для производства не найдены.");
    }

    const { pointsKadSheet, stockSheet, productionSheet } = cachedData;

    // Получаем список названий продуктов
    const productNames = productList.map((product) => product[0]);
    const allProductions = productionSheet.map((production) => production[0]);
    initializeCombinationCache(allProductions);

    // Фильтруем подходящие производства
    const suitableProductions = filterProductionsByAllProducts(
      stockSheet,
      productNames
    );

    let nearestDistance = Number.MAX_VALUE;
    let nearestCoords = null;

    // Вычисляем ближайшую точку КАД
    if (pointsKadSheet && destination) {
      ({ distance: nearestDistance, coordinates: nearestCoords } =
        await nearestExitKad(pointsKadSheet, destination));
    } else {
      console.warn("Missing pointsKadSheet or destination.");
    }

    const distances = {};
    const materialCosts = {};
    const deliveryCosts = {};
    const updateDates = {};
    const tripsCounts = {};
    const totalCosts = {}; // Объект для хранения общей суммы
    let totalPallets = 0;
    const addressesProductions = {};

    await Promise.all(
      suitableProductions.map(async (production) => {
        try {
          // Находим индекс производства в productionSheet
          const productionIndex = productionSheet.findIndex(
            (row) => row[0] === production
          );

          if (
            productionIndex !== -1 &&
            productionSheet[productionIndex].length > 1
          ) {
            // Извлекаем адрес из столбца C (индекс 2)
            const address = productionSheet[productionIndex][2];
            addressesProductions[production] = address || "Адрес не указан";

            const coord = productionSheet[productionIndex][1] || defaultCoord;

            // Проверяем, есть ли кэшированные расстояния
            if (cachedDistances[coord + "-" + destination]) {
              distances[production] =
                cachedDistances[coord + "-" + destination];
            } else {
              distances[production] = await getCachedDistance(
                coord,
                destination
              );
            }

            // Рассчитываем общее количество рейсов
            const tripsCount = productList.reduce(
              (totalTrips, [productName, quantity]) => {
                const stockEntry = stockSheet.find(
                  (row) => row[2] === production && row[0] === productName
                );

                if (!stockEntry) {
                  console.warn(
                    `No stock entry found for ${productName} at ${production}`
                  );
                  return totalTrips;
                }

                // Объем поддона для текущего товара
                const palletVolume =
                  parseFloat(stockEntry[7]?.replace(",", ".")) || 1;

                // Максимальная вместимость поддонов для метода доставки
                const maxCapacity =
                  deliveryMethod === "Шаланда" ||
                  deliveryMethod === "Оптимальный способ"
                    ? parseFloat(
                        stockSheet[productionIndex][8]?.replace(",", ".")
                      )
                    : deliveryMethod === "Манипулятор 10т"
                    ? parseFloat(
                        stockSheet[productionIndex][9]?.replace(",", ".")
                      )
                    : parseFloat(
                        stockSheet[productionIndex][10]?.replace(",", ".")
                      );

                if (!maxCapacity) {
                  console.warn(
                    `Invalid max capacity for delivery method ${deliveryMethod} at ${production}`
                  );
                  return totalTrips;
                }

                // Количество поддонов для текущего товара
                const palletsForProduct = Math.ceil(quantity / palletVolume);
                totalPallets += palletsForProduct;

                // Количество рейсов для текущего товара
                const tripsForProduct = palletsForProduct / maxCapacity;
                //console.log("tripsForProduct", tripsForProduct);

                // Суммируем рейсы для всех товаров
                return totalTrips + tripsForProduct;
              },
              0
            );

            totalPallets = Math.ceil(totalPallets); // Округляем общее количество поддонов, если требуется

            tripsCounts[production] = Math.ceil(tripsCount); // Округляем общее количество рейсов вверх
            /*console.log(
              `Trips count for ${production}:`,
              tripsCounts[production]
            );*/

            // Рассчитываем стоимость материалов
            const cost = productList.reduce((sum, [productName, quantity]) => {
              //console.log("sum для стоимост ", sum)
              const stockEntry = stockSheet.find(
                (row) => row[2] === production && row[0] === productName
              );
              if (!stockEntry) {
                console.warn(
                  `No stock entry found for ${productName} at ${production}`
                );
                return sum;
              }

              // Получаем дату обновления цены
              const updateDate = stockEntry[6] || "Не указано";

              if (!updateDates[production]) {
                updateDates[production] = updateDate;
              } else if (updateDate < updateDates[production]) {
                updateDates[production] = updateDate;
              }

              let priceColumn = 4;
              if (paymentMethod !== "cash") {
                priceColumn = 5;
              }

              const itPrice =
                parseFloat(
                  Math.ceil(quantity / stockEntry[7].replace(",", ".")) *
                    stockEntry[7].replace(",", ".")
                ) * parseInt(stockEntry[priceColumn].replace(/\s+/g, ""));
              /*console.log(
                "факт обхем",
                parseFloat(
                  Math.ceil(quantity / stockEntry[7].replace(",", ".")) *
                    stockEntry[7].replace(",", ".")
                )
              );*/
              return sum + itPrice;
            }, 0);

            materialCosts[production] = Math.ceil(cost); // Округляем итоговую стоимость

            // Рассчитываем стоимость доставки
            const deliveryCost = calculateDeliveryCost(
              productionSheet,
              production,
              deliveryMethod,
              distances[production],
              tripsCounts[production],
              totalPallets,
              stockSheet
            );

            deliveryCosts[production] =
              deliveryCost !== null ? deliveryCost : "Не удалось рассчитать";

            // Рассчитываем общую сумму
            totalCosts[production] =
              parseInt(String(materialCosts[production]).replace(/\s+/g, "")) +
              (deliveryCosts[production] !== "Не удалось рассчитать"
                ? parseInt(
                    String(deliveryCosts[production]).replace(/\s+/g, "")
                  ) * tripsCounts[production]
                : 0);
            //console.log("тотальные суммы ", totalCosts);
          } else {
            // Если производство не найдено, добавляем предупреждение
            console.warn(`No coordinates found for production: ${production}`);
            distances[production] = Number.MAX_VALUE;
            materialCosts[production] = 0;
            deliveryCosts[production] = "Не удалось рассчитать";
            tripsCounts[production] = "Не удалось рассчитать";
            totalCosts[production] = "Не удалось рассчитать";
          }
        } catch (error) {
          console.warn(`Error processing production ${production}:`, error);
        }
      })
    );

    // Финальный результат
    const resultTable = suitableProductions.map((production) => {
      const distance = distances[production];
      const cost = materialCosts[production];
      const deliveryCost = deliveryCosts[production];
      const tripsCount = tripsCounts[production];
      const totalCost = totalCosts[production];
      const updateDate = updateDates[production];
      return [
        production, // Название производства
        formatNumberWithSpaces(distance), // Расстояние до пункта назначения
        formatNumberWithSpaces(cost), // Сумма стоимости материалов
        formatNumberWithSpaces(deliveryCost), // Стоимость доставки
        tripsCount, // Количество рейсов
        formatNumberWithSpaces(totalCost), // Общая сумма
        updateDate, // Дата обновления цены
      ];
    });

    // Лог финального результата
    console.log("Final Result Table:", resultTable);

    return {
      data: resultTable,
      kadDistance: nearestDistance,
      addressesProductions: addressesProductions, // Можно добавить адреса при необходимости
      updateDates, // Возвращаем даты обновления
      deliveryCosts, // Возвращаем стоимости доставки
      tripsCounts, // Возвращаем количество рейсов
      totalCosts, // Возвращаем общую сумму
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

function generateCacheKey(coord1, coord2) {
  return [coord1, coord2].sort().join("-"); // Сортируем координаты и соединяем их через "-"
}

async function getCachedDistance(coord1, coord2) {
  const key = generateCacheKey(coord1, coord2); // Используем функцию для генерации ключа
  //console.log(`Проверка кэша для ключа: ${key}`);

  if (cachedDistances[key] !== undefined) {
    //console.log(`Использование закэшированного расстояния для ключа: ${key}, значение: ${cachedDistances[key]}`);
    return cachedDistances[key];
  }

  //console.log(`Расстояние для ключа ${key} не найдено в кэше. Выполняется запрос к серверу...`);
  const distance = await fetchDistance(coord1, coord2); // Запрос к серверу
  cachedDistances[key] = distance; // Сохранение результата в кэш
  //console.log(`Сохранение расстояния в кэш для ключа: ${key}, значение: ${distance}`);

  return distance;
}
async function nearestExitKad(pointsKadSheet, destination) {
  try {
    if (
      !pointsKadSheet ||
      !Array.isArray(pointsKadSheet) ||
      pointsKadSheet.length === 0
    ) {
      console.error(
        "pointsKadSheet пуст или не является массивом:",
        pointsKadSheet
      );
      return {
        distance: Number.MAX_VALUE,
        coordinates: null,
      };
    }

    let nearestExit = {
      distance: Number.MAX_VALUE,
      coordinates: null,
    };

    // Используем кэш для вычисления расстояний
    await Promise.all(
      pointsKadSheet.slice(1).map(async (row) => {
        const coord = row[0];
        if (!coord) {
          console.warn("Некорректная строка в pointsKadSheet, пропуск:", row);
          return Number.MAX_VALUE;
        }

        const distance = await getCachedDistance(coord, destination); // Используем getCachedDistance

        if (isNaN(distance)) {
          console.warn(
            `Некорректное значение расстояния для координаты ${coord}: ${distance}`
          );
          return Number.MAX_VALUE; // Возвращаем максимальное значение, чтобы исключить его из расчета минимального расстояния
        }

        if (distance < nearestExit.distance) {
          nearestExit = {
            distance: distance,
            coordinates: coord,
          };
        }

        return distance;
      })
    );

    if (nearestExit.distance === Infinity) {
      console.error("Все расстояния равны бесконечности, что-то пошло не так.");
      return {
        distance: Number.MAX_VALUE,
        coordinates: null,
      };
    }

    return nearestExit;
  } catch (error) {
    console.error("Ошибка в функции nearestExitKad:", error);
    return {
      distance: Number.MAX_VALUE,
      coordinates: null,
    };
  }
}

function filterProductionsByAllProducts(stockSheet, targetProducts) {
  //console.log("target ", targetProducts);
  const productionMap = stockSheet.reduce((map, row) => {
    const production = row[2];
    const product = row[0];
    if (!map[production]) map[production] = [];
    map[production].push(product);
    return map;
  }, {});

  //console.log("productionMap: ", productionMap);
  const filteredProductions = Object.keys(productionMap).filter((production) =>
    targetProducts.every((product) =>
      productionMap[production].includes(product)
    )
  );

  //console.log("filteredProductions: ", filteredProductions);
  return filteredProductions;
}

function formatNumberWithSpaces(num) {
  if (num == null) {
    return "0"; // Или другой подходящий вариант
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

async function getProductSizeByName(productName) {
  try {
    if (cachedData === null) {
      console.log("Данные не найдены в кеше. Запрашиваем fetchDataGAZ...");
      cachedData = await fetchDataGAZ();
      console.log("Данные успешно загружены:", cachedData);
    } else {
      console.log("Используем кэшированные данные.");
    }

    const data = cachedData;
    const stockSheet = data.stockSheet;

    // Находим строку с нужным наименованием продукта
    const productRow = stockSheet.find((row) => row[0] === productName);

    if (!productRow) {
      console.error(`Продукт ${productName} не найден.`);
      return null;
    }

    console.log(`Найдена строка для продукта ${productName}:`, productRow);

    // Получаем значение из столбца H (индекс 7)
    const sizeStr = productRow[7]; // Столбец H
    const size = parseFloat(sizeStr.replace(",", ".").replace(/\s+/g, ""));

    if (isNaN(size)) {
      console.error(
        `Некорректный размер упаковки (${sizeStr}) для продукта ${productName}`
      );
      return null;
    }

    return size;
  } catch (error) {
    console.error("Ошибка при выполнении getProductSizeByName:", error);
    throw error;
  }
}

async function listPricesGAZ(
  namesAndQuantities,
  paymentMethod,
  productionName
) {
  try {
    console.log("Запуск listPricesGAZ с параметрами:", {
      namesAndQuantities,
      paymentMethod,
      productionName,
    });

    if (cachedData === null) {
      console.log("Данные не найдены в кеше. Запрашиваем fetchDataGAZ...");
      cachedData = await fetchDataGAZ();
      console.log("Данные успешно загружены:", cachedData);
    } else {
      console.log("Используем кэшированные данные.");
    }

    const data = cachedData;
    const stockSheet = data.stockSheet;
    const priceColumn = paymentMethod === "cash" ? 4 : 5;

    console.log(
      `Используем колонку цен: ${priceColumn === 4 ? "наличные" : "безнал"}`
    );

    // Получаем список цен на продукты с учетом количества
    const prices = namesAndQuantities.map(([productName, quantity]) => {
     /* console.log(
        `Обработка продукта: ${productName}, количество: ${quantity}`
      );*/

      const productRow = stockSheet.find(
        (row) => row[2] === productionName && row[0] === productName
      );

      if (!productRow) {
        console.error(
          `Продукт ${productName} не найден в производстве ${productionName}`
        );
        return null;
      }

      console.log(`Найдена строка для продукта ${productName}:`, productRow);

      // Получаем цену за единицу и преобразуем в число
      const pricePerUnit = parseFloat(
        productRow[priceColumn].replace(/\s+/g, "")
      );
      if (isNaN(pricePerUnit)) {
        console.error(
          `Некорректная цена (${productRow[priceColumn]}) для продукта ${productName}`
        );
        return null;
      }

     /* console.log(
        `Цена за единицу для продукта ${productName}: ${pricePerUnit}`
      );*/


      return pricePerUnit;
    });

    // Используем глобальную переменную, если необходимо
    const cheapestMethod = globalCheapestMethod;
   /* console.log("Метод доставки:", cheapestMethod);*/

    // Возвращаем объект с ценами и методом доставки
    const result = {
      prices,
      cheapestMethod,
    };

    //console.log("Результат listPricesGAZ:", result);
    return result;
  } catch (error) {
    console.error("Ошибка при выполнении listPricesGAZ:", error);
    throw error;
  }
}

module.exports = {
  getProductionDataGAZ,
  listPricesGAZ,
  nearestExitKad,
  getProductSizeByName,
};
