import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { fetchIdsOS, sendDataToBitrix24, fetchIdsJBI, fetchIdsJBIMoscow, fetchIdsGAZ} from '../fetches';
import styledComponents from "../additionalComponents/StyledComponents";
import {IconButton } from '@mui/material';

const { Overlay, Icon } = styledComponents;

// Стилизованные компоненты


const Content = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;



const ModalFormBitrix6OS = ({ isModalBitrixVisible, onClose, dealId, modalFormData, onSuccess,  selectedOption}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchIdsMap = {
    "Общестрой": fetchIdsOS,
    "ЖБИ": fetchIdsJBI,
    "ЖБИ Москва": fetchIdsJBIMoscow,
    "Газобетон": fetchIdsGAZ,
  };
  
  const fetchIds = fetchIdsMap[selectedOption] || null;
  
  if (!fetchIds) {
    throw new Error(`Неизвестная опция: ${selectedOption}`);
  }

  useEffect(() => {
    if (isModalBitrixVisible && modalFormData.length > 0) {
      console.log('Наименования товаров:', modalFormData[0][0]);
    }
  }, [isModalBitrixVisible, modalFormData]);

  useEffect(() => {
    if (!isModalBitrixVisible) {
      setIsLoading(false);
    }
  }, [isModalBitrixVisible]);

  const handleSubmit = useCallback(async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);

    try {
      const numericDealId = parseInt(dealId, 10);
      if (isNaN(numericDealId)) {
          throw new Error('Некорректный ID сделки.');
      }

      const searchStrings = modalFormData.map(item => item[0]);
      const productIds = await fetchIds(searchStrings);

      const productRows = modalFormData.map((item, index) => ({
        PRODUCT_ID: parseInt(productIds[index], 10),
        PRICE: parseInt(item[2], 10),
        QUANTITY: parseInt(item[1], 10),
      }));

      console.log('Отправляемые данные:', {
        numericDealId,
        productRows,
      });

      const response = await sendDataToBitrix24(numericDealId, productRows);
      console.log('Data sent successfully', response);
      setSuccessMessage(`Данные о товарах для сделки с ID ${numericDealId} успешно сохранены.\nДля отображения изменений обновите страницу (после обновления данные в калькуляторе будут сброшены)`);

      setIsLoading(false);
      setTimeout(() => {
        onSuccess();
      }, 3000);
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setErrorMessage(error.message || 'Ошибка при сохранении данных.');
      setIsLoading(false);
    }
  }, [dealId, modalFormData, fetchIds, onSuccess]);

  useEffect(() => {
    if (window.BX24) {
      window.BX24.init(() => {
        // Получаем текущие размеры окна
        const width = window.innerWidth; // Текущая ширина
        const height = window.innerHeight + 2000; // Добавляем 200 пикселей к высоте
  
        // Устанавливаем новые размеры окна IFRAME
        window.BX24.resizeWindow(width, height);
      });
    }
  }, []);


  useEffect(() => {
    if (isModalBitrixVisible) {
      handleSubmit();
    }
  }, [isModalBitrixVisible, dealId, handleSubmit]);

  if (!isModalBitrixVisible) {
    return null;
  }

  return (
    <Overlay>
      <Content>
        <Header>
          <h2>Обработка данных</h2>
          <IconButton onClick={onClose}>
          <Icon className="material-icons">close</Icon>
          </IconButton>        
        </Header>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {isLoading && <p>Сохранение данных...</p>}
      </Content>
    </Overlay>
  );
};

export default ModalFormBitrix6OS;