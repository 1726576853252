import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styledComponents from "../additionalComponents/StyledComponents";

const {
  TooltipContainer,
  TooltipText,
} = styledComponents;

const TooltipPortal = ({ children, position }) => {
  return ReactDOM.createPortal(
    <TooltipText position={position} visible={!!children}>
      {children}
    </TooltipText>,
    document.body
  );
};

const TooltipWrapper = ({ text, children }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    setPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {visible && <TooltipPortal position={position}>{text}</TooltipPortal>}
    </TooltipContainer>
  );
};

export default TooltipWrapper;