import React, { useState, useEffect } from "react";
import HeaderOS from "../componentsOS/headerOS";
import Choice0OS from "../componentsOS/choice0OS";
import BasicInfo1OS from "../componentsOS/basicInfo1OS";
import Products2OS from "../componentsOS/products2OS";
import CostComparison3OS from "../componentsOS/costComparison3OS";
import CommercialOffer4OS from "../componentsOS/commercialOffer4OS";
import ModalFormBitrix6OS from "../componentsOS/modalFormBitrix6OS";
import TestComponent from "../componentsOS/test";
import {
  fetchAllDataOS,
  fetchAllDataJBI,
  fetchAllDataJBIMoscow,
  fetchAllDataGAZ,
} from "../fetches";
import styledComponents from "../additionalComponents/StyledComponents";
const { Loading} =
styledComponents;

const AppOS = () => {
  const [clientName, setClientName] = useState("имя клиента");
  const [isProductsSectionVisible, setIsProductsSectionVisible] =
    useState(false);
  const [selectedSubsection, setSelectedSubsection] = useState("");
  const [selectedDeliveryOption, setSelectedDeliveryOption] =
    useState("Оптимальный способ");
  const [isCostComparisonVisible, setIsCostComparisonVisible] = useState(false);
  const [isCommercialOfferVisible, setIsCommercialOfferVisible] =
    useState(false);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [clientCoordinates, setClientCoordinates] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [totalVolume, setTotalVolume] = useState("");
  const [namesAndQuantities, setNamesAndQuantities] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState([]);
  const [selectedProductionName, setSelectedProductionName] = useState("");
  const [modalFormData, setModalFormData] = useState(null);
  const [isSelfPickup, setIsSelfPickup] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Общестрой");
  const [dealId, setDealId] = useState(null);
  const [dealAddress, setDealAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [analogData, setAnalogData] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const [isModalBitrixVisible, setIsModalBitrixVisible] = useState(false);
  const [factVolumeGaz, setFactVolumeGaz] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchAllDataOS(),
          fetchAllDataJBI(),
          fetchAllDataJBIMoscow(),
          fetchAllDataGAZ(),
        ]);
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const resetState = () => {
    setSelectedSubsection("");
    setIsCostComparisonVisible(false);
    setIsCommercialOfferVisible(false);
    setTotalWeight("");
    setTotalVolume("");
    setNamesAndQuantities([]);
    setSelectedProduction([]);
    setSelectedProductionName("");
    setModalFormData(null);
    setAnalogData(null);
    setIsLoading(false);
  };

  const handleSelfPickupChange = (isSelfPickup) => {
    setIsSelfPickup(isSelfPickup);
    resetState();
  };

  const handleSelectedProduction = (data) => {
    if (selectedProduction.length > 0 && selectedProduction[0] !== data[0]) {
      setSelectedProduction([data]);
      setSelectedProductionName(data[0]);
    } else {
      setSelectedProduction([data]);
      setSelectedProductionName(data[0]);
    }
  };

  const handleOptimalRouteSelection = (optimalRoute) => {
    const selectedProductionData = [
      optimalRoute.mainProduction,
      optimalRoute.numOfTrips * optimalRoute.deliveryCostPerTrip +
        optimalRoute.additionalCost,
      optimalRoute.numOfTrips,
      optimalRoute.updateDates,
    ];
    setSelectedProduction([selectedProductionData]);
    setSelectedProductionName(optimalRoute.mainProduction);
    setIsCommercialOfferVisible(true);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="AppOS">
      <TestComponent
        onDealId={setDealId}
        onDealAddress={setDealAddress}
        onContactData={setClientName}
      />
      <Choice0OS onChoiceChange={setSelectedOption} />
      <HeaderOS selectedOption={selectedOption} />
      <BasicInfo1OS
        selectedOption={selectedOption}
        selectedDeliveryOption={selectedDeliveryOption}
        onDeliveryChange={setSelectedDeliveryOption}
        paymentMethod={paymentMethod}
        onPaymentChange={setPaymentMethod}
        clientCoordinates={clientCoordinates}
        onCoordinatesChange={setClientCoordinates}
        setIsProductsSectionVisible={setIsProductsSectionVisible}
        onSelfPickupChange={handleSelfPickupChange}
        dealCoordinates={dealAddress || "Адрес отсутствует"}
      />
      <Products2OS
        key={selectedOption}
        selectedOption={selectedOption}
        isProductsSectionVisible={isProductsSectionVisible}
        onTotalWeightChange={setTotalWeight}
        onTotalVolumeChange={setTotalVolume}
        onNamesAndQuantitiesChange={setNamesAndQuantities}
        toggleCostComparisonVisibility={setIsCostComparisonVisible}
        toggleCommercialOfferVisibility={setIsCommercialOfferVisible}
        onAnalogDataChange={setAnalogData}
        namesAndQuantities={namesAndQuantities}
        onFactVolumeChange={setFactVolumeGaz} // callback для обновления factVolumeGaz
        />
      <CostComparison3OS
        selectedSubsection={selectedSubsection}
        selectedDeliveryOption={selectedDeliveryOption}
        isCostComparisonVisible={isCostComparisonVisible}
        paymentMethod={paymentMethod}
        clientCoordinates={clientCoordinates}
        totalWeight={totalWeight}
        totalVolume={totalVolume}
        namesAndQuantities={namesAndQuantities}
        toggleCommercialOfferVisibility={setIsCommercialOfferVisible}
        selectedProduction={selectedProduction}
        onSelectedProduction={handleSelectedProduction}
        isSelfPickup={isSelfPickup}
        onOptimalRouteSelect={handleOptimalRouteSelection}
        selectedOption={selectedOption}
        onSetSortedData={(data) => setSortedData(data)} // Убедитесь, что это передаётся
        sortedData={sortedData}
      />
      <CommercialOffer4OS
        clientName={clientName}
        isVisible={isCommercialOfferVisible}
        selectedProduction={selectedProduction}
        selectedProductionName={selectedProductionName}
        namesAndQuantities={namesAndQuantities}
        selectedDeliveryOption={selectedDeliveryOption}
        paymentMethod={paymentMethod}
        setIsModalBitrixVisible={setIsModalBitrixVisible}
        onSaveToPDF={setModalFormData}
        isSelfPickup={isSelfPickup}
        selectedOption={selectedOption}
        analogData={analogData}
        totalWeight={totalWeight}
        clientCoordinates={clientCoordinates}
        totalVolume={totalVolume}
        sortedData={sortedData}
        factVolumeGaz={factVolumeGaz}
      />
      <ModalFormBitrix6OS
        isModalBitrixVisible={isModalBitrixVisible}
        onClose={() => setIsModalBitrixVisible(false)}
        dealId={dealId}
        modalFormData={modalFormData}
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default AppOS;
