let cacheOS = null; // Кэш для данных
let cacheTimestampOS = null; // Время последнего обновления кэша
let cacheJBI = null; // Кэш для данных
let cacheTimestampJBI = null; // Время последнего обновления кэша
let cacheJBIMoscow = null; // Кэш для данных
let cacheTimestampJBIMoscow = null; // Время последнего обновления кэша
let cacheGAZ = null; // Кэш для данных
let cacheTimestampGAZ = null; // Время последнего обновления кэша
const CACHE_TTL = 15 * 60 * 1000; // 15 минут в миллисекундах

//расчет растояния между двумя точками
export async function fetchDistance(origin, destination) {
  const response = await fetch(`/api/km`, {
    // Укажите полный URL
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ origin, destination }),
  });

  if (!response.ok) {
    const errorBody = await response.text();
    console.error(`Failed to fetch data: ${response.statusText}`, errorBody);
    throw new Error(`Failed to fetch data: ${response.statusText}`);
  }

  const data = await response.json();
  return data.distance;
}

//**************************ГЛАВНАЯ ФУНКЦИЯ ОБЩЕСТРОЯ******************************* */

export async function fetchAllDataOS() {
  const now = Date.now();

  // Проверяем, если кэш существует и не устарел
  if (cacheOS && cacheTimestampOS && now - cacheTimestampOS < CACHE_TTL) {
    console.log("Возвращаем данные из кэша");
    return cacheOS; // Возвращаем закэшированные данные
  }

  console.log("Запрашиваем новые данные с сервера");
  const response = await fetch(`/api/dataOS`);

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  const data = await response.json();

  // Сохраняем данные в кэш
  cacheOS = data;
  cacheTimestampOS = now;

  return data;
}

//**************************ГЛАВНАЯ ФУНКЦИЯ ЖБИ******************************* */

export async function fetchAllDataJBI() {
  const now = Date.now();

  // Проверяем, если кэш существует и не устарел
  if (cacheJBI && cacheTimestampJBI && now - cacheTimestampJBI < CACHE_TTL) {
    console.log("Возвращаем данные из кэша");
    return cacheJBI; // Возвращаем закэшированные данные
  }

  console.log("Запрашиваем новые данные с сервера");
  const response = await fetch(`/api/dataJBI`);

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  const data = await response.json();

  // Сохраняем данные в кэш
  cacheJBI = data;
  cacheTimestampJBI = now;

  return data;
}

//********************************ГЛАВНАЯ ФУНКЦИЯ ЖБИ МОСКВА******************************************** */
export async function fetchAllDataJBIMoscow() {
  const now = Date.now();

  // Проверяем, если кэш существует и не устарел
  if (
    cacheJBIMoscow &&
    cacheTimestampJBIMoscow &&
    now - cacheTimestampJBIMoscow < CACHE_TTL
  ) {
    console.log("Возвращаем данные из кэша");
    return cacheJBIMoscow; // Возвращаем закэшированные данные
  }

  console.log("Запрашиваем новые данные с сервера");
  const response = await fetch(`/api/dataJBIMoscow`); // Полный маршрут

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  const data = await response.json();

  // Сохраняем данные в кэш
  cacheJBIMoscow = data;
  cacheTimestampJBIMoscow = now;

  return data;
}

//**************************ГЛАВНАЯ ФУНКЦИЯ ГАЗОБЕТОНА******************************* */

export async function fetchAllDataGAZ() {
  const now = Date.now();

  // Проверяем, если кэш существует и не устарел
  if (cacheGAZ && cacheTimestampGAZ && now - cacheTimestampGAZ < CACHE_TTL) {
    console.log("Возвращаем данные из кэша");
    return cacheGAZ; // Возвращаем закэшированные данные
  }

  console.log("Запрашиваем новые данные с сервера");
  const response = await fetch(`/api/dataGAZ`);

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  const data = await response.json();

  // Сохраняем данные в кэш
  cacheGAZ = data;
  cacheTimestampGAZ = now;

  return data;
}

//**************************ОБЩЕСТРОЙ******************************* */

export async function fetchDataOS() {
  try {
    // Используем закэшированные данные из fetchAllDataOS
    const data = await fetchAllDataOS();
    return data;
  } catch (error) {
    console.error("Ошибка при получении данных из fetchAllDataOS:", error);
    throw error; // Пробрасываем ошибку дальше
  }
}

export async function fetchDictOfTypesSubsectionsNamesOS(key = "stockSheet") {
  try {
    // Получаем данные из кэша или загружаем их через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const result = {};
    const rows = data[key];

    // Пропускаем первую строку (заголовки)
    for (let i = 1; i < rows.length; i++) {
      // Извлекаем значения из столбцов A, D, C, E и F
      const category = rows[i][0]; // Столбец A
      const subcategory = rows[i][3]; // Столбец D
      const itemName = rows[i][2]; // Столбец C
      const priceCash = rows[i][4]; // Столбец E (цена нал)
      const priceNonCash = rows[i][5]; // Столбец F (цена безнал)

      // Проверяем, что значения существуют
      if (category && subcategory && itemName) {
        if (!result[category]) {
          result[category] = {};
        }

        if (!result[category][subcategory]) {
          result[category][subcategory] = [];
        }

        // Добавляем массив с наименованием, ценой нал, ценой безнал
        result[category][subcategory].push([itemName, priceCash, priceNonCash]);
      }
    }

    return result;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchMassVolumeOS(key = "stockSheet") {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки

    const result = {};

    value.forEach((arr) => {
      const itemName = arr[2]; // Столбец C - наименование

      // Преобразуем строку с запятой в число с плавающей запятой
      const mass = parseFloat(arr[7]?.replace(",", ".")); // Столбец H - масса
      const volume = parseFloat(arr[8]?.replace(",", ".")); // Столбец I - объем
      const unit = arr[9]?.trim() || "шт"; // Столбец J - единица измерения (по умолчанию "шт")

      if (itemName && itemName.trim() !== "") {
        result[itemName] = {
          mass: !isNaN(mass) ? mass : 0, // Если значение массы не число, установить 0
          volume: !isNaN(volume) ? volume : 0, // Если значение объема не число, установить 0
          unit: unit, // Устанавливаем единицу измерения
        };
      }
    });

    return result;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchDeliveryOS(key = "deliverySheet") {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки
    const filteredValues = value.map((arr) => arr[0]); // Извлекаем значения из столбца A

    return filteredValues;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchIdsOS(searchStrings, key = "stockSheet") {
  try {
    // Получаем словарь deliveryIds через fetchDeliveryIdOS
    const deliveryIds = await fetchDeliveryIdOS();

    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки

    // Проходим по массиву searchStrings и находим значения
    const result = searchStrings.map((searchString) => {
      // Проверяем, если searchString есть в словаре deliveryIds
      if (deliveryIds.hasOwnProperty(searchString)) {
        return deliveryIds[searchString]; // Возвращаем ID из словаря
      }

      // Ищем строку по полному соответствию в столбце C
      const foundRow = value.find((arr) => arr[2] === searchString); // Столбец C (индекс 2)

      if (foundRow) {
        return foundRow[16]; // Возвращаем значение из столбца Q (индекс 16)
      } else {
        throw new Error(
          `Строка с текстом '${searchString}' не найдена в столбце C.`
        );
      }
    });

    return result; // Возвращаем массив найденных значений
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchDeliveryIdOS(key = "deliverySheet") {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки

    // Создаём объект (словарь), где ключи из столбца A, а значения из столбца F
    const result = {};
    value.forEach((arr) => {
      const columnA = arr[0]; // Столбец A
      const columnF = arr[5]; // Столбец F

      // Добавляем запись в словарь, если столбцы A и F существуют
      if (columnA && columnF) {
        result[columnA] = columnF;
      }
    });

    return result;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchAnalog(key = "stockSheet") {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки
    const result = [];

    value.forEach((arr) => {
      const itemName = arr[2]; // Столбец C - номенклатура
      const price = arr[4]; // Столбец E - цена
      const density = arr[10]; // Столбец K - плотность
      const analogCategory = arr[11]; // Столбец L - категория аналога
      const thickness = arr[12]; // Столбец M - толщина
      const area = arr[13]; // Столбец N - м²
      const packQuantity = arr[14]; // Столбец O - количество штук в упаковке
      const extraThickness = arr[15]; // Столбец P - дополнительная толщина утеплителя
      const manufacture = arr[1]; // Столбец B - производсто

      if (itemName && price && ((density && analogCategory) || thickness)) {
        result.push({
          itemName,
          price,
          density,
          analogCategory,
          thickness,
          area,
          packQuantity,
          extraThickness,
          manufacture,
        });
      }
    });

    return result;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchAnalogsOSKP(itemName, quantity, key = "stockSheet") {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки
    const result = [];

    // Находим основную информацию о товаре
    const targetProduct = value.find((arr) => arr[2] === itemName);
    if (!targetProduct) {
      console.warn(`Товар '${itemName}' не найден в таблице.`);
      return { popular: [], budget: [] }; // Возвращаем пустые списки, если товар не найден
    }

    // Проверяем тип материала
    const isInsulation =
      targetProduct[10] &&
      targetProduct[11] &&
      targetProduct[13] &&
      targetProduct[14] &&
      targetProduct[15];
    const isOSB = targetProduct[12]; // Столбец M - Толщина для OSB

    if (!isInsulation && !isOSB) {
      // Если материал не распознан, добавляем его как есть
      result.push({
        itemName: itemName, // Номенклатура аналога
        quantity: quantity, // Количество упаковок
        price: parseInt(targetProduct[4].replace(/\s/g, "")),
        totalPrice: parseInt(targetProduct[4].replace(/\s/g, "")) * quantity,
      });
      return { popular: result, budget: result };
    }

    if (isInsulation) {
      // Утеплитель: вычисляем необходимое количество метров
      const requiredArea = Math.ceil(
        parseFloat(targetProduct[13].replace(/\s/g, "").replace(",", ".")) *
          quantity
      ); // Убираем пробелы, заменяем запятую на точку и округляем в большую сторону

      // Категории исходного товара
      const targetCategories = targetProduct[11]
        .split(",")
        .map((cat) => cat.trim());
      const targetExtraThickness = parseFloat(targetProduct[15]); // Значение в столбце P

      // Фильтруем аналоги для утеплителя
      const filteredData = value.filter((arr) => {
        const analogCategories = arr[11]?.split(",").map((cat) => cat.trim());
        const hasCommonCategory = analogCategories?.some((cat) =>
          targetCategories.includes(cat)
        );

        const analogExtraThickness = parseFloat(arr[15]); // Значение столбца P у аналога
        const thicknessDifference = Math.abs(
          analogExtraThickness - targetExtraThickness
        );

        // Аналог должен быть утеплителем, иметь хотя бы одну общую категорию и допустимое отличие по толщине
        return (
          arr[10] && // Плотность
          arr[11] && // Категории
          arr[13] && // Площадь
          arr[14] && // Количество в упаковке
          arr[15] && // Дополнительная толщина
          hasCommonCategory &&
          thicknessDifference <= 10 // Проверка, что отличие в толщине <= 10
        );
      });

      let cheapestAnalog = null; // Самый дешевый аналог
      let cheapestPopularAnalog = null; // Самый дешевый аналог с категорией "ПОПУЛЯРНОЕ"

      filteredData.forEach((arr) => {
        const pricePerUnit = parseFloat(arr[4].replace(/\s/g, "")); // Цена за упаковку
        const packArea = parseFloat(arr[13]); // Площадь упаковки (N)

        const packCount = Math.ceil(requiredArea / packArea); // Округляем до ближайшего большего целого

        const totalPrice = packCount * pricePerUnit;

        if (!isNaN(totalPrice)) {
          const analogData = {
            itemName: arr[2], // Номенклатура аналога
            quantity: packCount, // Количество упаковок
            price: pricePerUnit,
            totalPrice: totalPrice,
          };

          // Проверяем самый дешевый аналог
          if (!cheapestAnalog || totalPrice < cheapestAnalog.totalPrice) {
            cheapestAnalog = analogData;
          }

          // Проверяем самый дешевый популярный аналог
          if (
            arr[11]?.includes("ПОПУЛЯРНОЕ") &&
            (!cheapestPopularAnalog ||
              totalPrice < cheapestPopularAnalog.totalPrice)
          ) {
            cheapestPopularAnalog = analogData;
          }
        }
      });

      // Если подходящих аналогов нет, добавляем исходный товар
      if (!cheapestAnalog) {
        cheapestAnalog = {
          itemName,
          quantity,
          price: parseFloat(targetProduct[4].replace(/\s/g, "")),
          totalPrice:
            parseFloat(targetProduct[4].replace(/\s/g, "")) * quantity,
        };
      }

      if (!cheapestPopularAnalog && targetProduct[11]?.includes("ПОПУЛЯРНОЕ")) {
        cheapestPopularAnalog = {
          itemName,
          quantity,
          price: parseFloat(targetProduct[4]),
          totalPrice: parseFloat(targetProduct[4]) * quantity,
        };
      }

      // Возвращаем результат сразу
      return {
        popular: cheapestPopularAnalog ? [cheapestPopularAnalog] : [],
        budget: cheapestAnalog ? [cheapestAnalog] : [],
      };
    }

    if (isOSB) {
      // OSB: ищем аналоги с такой же толщиной (M)
      const targetThickness = targetProduct[12]; // Толщина (столбец M)

      // Фильтруем аналоги для OSB
      const filteredData = value.filter((arr) => {
        return arr[12] === targetThickness && arr[2] !== itemName; // Аналоги с такой же толщиной, исключая исходный товар
      });

      if (filteredData.length > 0) {
        // Находим самый бюджетный аналог
        const budgetAnalog = filteredData.reduce((prev, curr) => {
          const prevPrice = parseFloat(prev[4].replace(/\s/g, "")); // Цена предыдущего аналога
          const currPrice = parseFloat(curr[4].replace(/\s/g, "")); // Цена текущего аналога
          return prevPrice < currPrice ? prev : curr;
        });

        // Добавляем самый бюджетный аналог в результат
        result.push({
          itemName: budgetAnalog[2], // Номенклатура аналога
          quantity,
          price: parseFloat(budgetAnalog[4].replace(/\s/g, "")), // Цена аналога
          totalPrice: parseFloat(budgetAnalog[4].replace(/\s/g, "")) * quantity,
        });
      } else {
        // Если аналогов нет, добавляем исходный товар
        result.push({
          itemName, // Название исходного товара
          quantity,
          price: parseFloat(targetProduct[4].replace(/\s/g, "")), // Цена исходного товара
          totalPrice:
            parseFloat(targetProduct[4].replace(/\s/g, "")) * quantity,
        });
      }
    }
    return { popular: result, budget: result };
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchTooltip(name, quantity, key = "stockSheet") {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки
    const result = [];

    value.forEach((arr) => {
      const itemName = arr[2]; // Столбец C - номенклатура
      const price = arr[4]; // Столбец E - цена
      const density = arr[10]; // Столбец K - плотность
      const analogCategory = arr[11]; // Столбец L - категория аналога
      const thickness = arr[12]; // Столбец M - толщина
      const area = arr[13]; // Столбец N - м² (метры в упаковке)
      const packQuantity = arr[14]; // Столбец O - количество штук в упаковке
      const extraThickness = arr[15]; // Столбец P - дополнительная толщина утеплителя
      const manufacture = arr[1]; // Столбец B - производитель

      // Проверка, что имя товара соответствует и поля не пустые
      if (itemName === name) {
        const itemInfo = {};

        itemInfo.itemName = itemName;
        if (price) itemInfo.price = price;
        if (density) itemInfo.density = density;
        if (analogCategory) itemInfo.analogCategory = analogCategory;
        if (thickness) itemInfo.thickness = thickness;

        // Вычисляем требуемый объем
        if (area && quantity) {
          console.log("a q", area, quantity);
          const parsedArea = parseFloat(area.replace(",", "."));
          const requiredVolume = Math.ceil(parsedArea * quantity);
          itemInfo.volumeRequired = requiredVolume; // Объем в м² для всего количества
          console.log("Required Volume:", requiredVolume);
        }

        if (extraThickness) itemInfo.extraThickness = extraThickness;
        if (manufacture) itemInfo.manufacture = manufacture;
        if (packQuantity) itemInfo.packQuantity = packQuantity;

        result.push(itemInfo); // Добавляем в результат
      }
    });

    return result;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

export async function fetchAllAnalogsOSKP(
  itemName,
  quantity,
  key = "stockSheet"
) {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataOS();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки
    const result = { popular: [], budget: [] };

    // Находим основную информацию о товаре
    const targetProduct = value.find((arr) => arr[2] === itemName);
    if (!targetProduct) {
      console.warn(`Товар '${itemName}' не найден в таблице.`);
      return result; // Возвращаем пустые списки, если товар не найден
    }

    // Проверяем тип материала
    const isInsulation =
      targetProduct[10] &&
      targetProduct[11] &&
      targetProduct[13] &&
      targetProduct[14] &&
      targetProduct[15];
    const isOSB = targetProduct[12]; // Столбец M - Толщина для OSB

    if (!isInsulation && !isOSB) {
      // Если материал не распознан, добавляем его как есть
      result.budget.push({
        itemName: itemName, // Номенклатура аналога
        quantity: quantity, // Количество упаковок
      });
      result.popular.push({
        itemName: itemName, // Номенклатура аналога
        quantity: quantity, // Количество упаковок
      });
      return result;
    }

    if (isInsulation) {
      // Утеплитель: вычисляем необходимое количество метров
      const requiredArea = Math.ceil(
        parseFloat(targetProduct[13].replace(/\s/g, "").replace(",", ".")) *
          quantity
      );
      const targetCategories = targetProduct[11]
        .split(",")
        .map((cat) => cat.trim());
      const targetExtraThickness = parseFloat(targetProduct[15]);

      // Фильтруем аналоги для утеплителя
      const filteredData = value.filter((arr) => {
        const analogCategories = arr[11]?.split(",").map((cat) => cat.trim());
        const hasCommonCategory = analogCategories?.some((cat) =>
          targetCategories.includes(cat)
        );
        const analogExtraThickness = parseFloat(arr[15]);
        const thicknessDifference = Math.abs(
          analogExtraThickness - targetExtraThickness
        );

        return (
          arr[10] && // Плотность
          arr[11] && // Категории
          arr[13] && // Площадь
          arr[14] && // Количество в упаковке
          arr[15] && // Дополнительная толщина
          hasCommonCategory &&
          thicknessDifference <= 10
        );
      });

      const budgetResults = [];
      const popularResults = [];

      filteredData.forEach((arr) => {
        const pricePerUnit = parseFloat(arr[4].replace(/\s/g, ""));
        const packArea = parseFloat(arr[13]);
        const packCount = Math.ceil(requiredArea / packArea);
        const totalPrice = packCount * pricePerUnit;

        if (!isNaN(totalPrice)) {
          const analogData = {
            itemName: arr[2],
            quantity: packCount,
            price: pricePerUnit,
            totalPrice: totalPrice,
          };

          budgetResults.push(analogData);
          if (arr[11]?.includes("ПОПУЛЯРНОЕ")) {
            popularResults.push(analogData);
          }
        }
      });

      // Сортируем бюджетные варианты по общей цене и берём только 10 самых дешевых
      const sortedBudgetResults = budgetResults
        .sort((a, b) => a.totalPrice - b.totalPrice)
        .slice(0, 5);

      // Сортируем популярные варианты по общей цене и берём только 10 самых дешевых
      const sortedPopularResults = popularResults
        .sort((a, b) => a.totalPrice - b.totalPrice)
        .slice(0, 100);

      result.budget = sortedBudgetResults;
      result.popular = sortedPopularResults;

      return result;
    }

    if (isOSB) {
      const targetThickness = targetProduct[12];
      const filteredData = value.filter((arr) => {
        return arr[12] === targetThickness && arr[2] !== itemName;
      });

      filteredData.forEach((arr) => {
        const pricePerUnit = parseFloat(arr[4].replace(/\s/g, ""));
        const totalPrice = pricePerUnit * quantity;

        const analogData = {
          itemName: arr[2],
          quantity: quantity,
          price: pricePerUnit,
          totalPrice: totalPrice,
        };

        result.budget.push(analogData);
        result.popular.push(analogData);
      });

      return result;
    }

    return result;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataOS:", error);
    throw error;
  }
}

//**************************ЖБИ******************************* */
export async function fetchDataJBI() {
  try {
    // Используем закэшированные данные из fetchAllDataOS
    const data = await fetchAllDataJBI();
    return data;
  } catch (error) {
    console.error("Ошибка при получении данных из fetchAllDataJBI:", error);
    throw error; // Пробрасываем ошибку дальше
  }
}

export async function fetchDictOfTypesSubsectionsNamesJBI(key = "stockSheet") {
  try {
    const data = await fetchAllDataJBI(); // Получаем данные из кэша или сервера
    let result = {};

    if (data.hasOwnProperty(key)) {
      for (let i = 1; i < data[key].length; i++) {
        // Извлекаем значения из столбцов A, D, C, E и F
        let category = data[key][i][0]; // Столбец A
        let subcategory = data[key][i][3]; // Столбец D
        let itemName = data[key][i][2]; // Столбец C
        let priceCash = data[key][i][4]; // Столбец E (цена нал)
        let priceNonCash = data[key][i][5]; // Столбец F (цена безнал)

        // Проверка на существование данных
        if (category && subcategory && itemName) {
          if (!result[category]) {
            result[category] = {};
          }

          if (!result[category][subcategory]) {
            result[category][subcategory] = [];
          }

          // Добавляем массив с наименованием, ценой нал, ценой безнал
          result[category][subcategory].push([
            itemName,
            priceCash,
            priceNonCash,
          ]);
        }
      }
      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchMassVolumeJBI(key = "stockSheet") {
  try {
    const data = await fetchAllDataJBI(); // Получаем данные из кэша или сервера

    if (data.hasOwnProperty(key)) {
      let value = data[key];
      value = value.slice(1); // Пропускаем заголовки

      let result = {};

      value.forEach((arr) => {
        let itemName = arr[2]; // Столбец C - наименование

        // Преобразуем строку с запятой в число с плавающей запятой
        let mass = arr[7] ? parseFloat(arr[7].replace(",", ".")) : 0; // Проверяем, что значение существует
        let volume = arr[8] ? parseFloat(arr[8].replace(",", ".")) : 0;
        let unit = arr[9] || "шт"; // Столбец J - единица измерения (по умолчанию "шт")

        if (itemName && itemName.trim() !== "") {
          result[itemName] = {
            mass: !isNaN(mass) ? mass : 0, // Если значение массы не число, установить 0
            volume: !isNaN(volume) ? volume : 0, // Если значение объема не число, установить 0
            unit: unit.trim(), // Устанавливаем единицу измерения
          };
        }
      });

      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchDeliveryJBI(key = "deliverySheet") {
  try {
    const data = await fetchAllDataJBI(); // Получаем данные из кэша или сервера

    if (data.hasOwnProperty(key)) {
      let value = data[key];
      value = value.slice(1); // Пропускаем заголовки
      let filteredValues = value.map((arr) => arr[0]); // Извлекаем только первый столбец
      return filteredValues;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchDeliveryIdJBI(key = "deliverySheet") {
  try {
    const data = await fetchAllDataJBI(); // Получаем данные из кэша или сервера

    if (data.hasOwnProperty(key)) {
      let value = data[key];
      value = value.slice(1); // Пропускаем заголовки

      // Создаем объект (словарь), где ключи из столбца A, а значения из столбца F
      let result = {};
      value.forEach((arr) => {
        let columnA = arr[0]; // Столбец A
        let columnF = arr[5]; // Столбец F

        // Добавляем запись в словарь, если столбец A и столбец F существуют
        if (columnA && columnF) {
          result[columnA] = columnF;
        }
      });

      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchIdsJBI(searchStrings, key = "stockSheet") {
  try {
    // Получаем словарь deliveryIds через fetchDeliveryIdOS
    const deliveryIds = await fetchDeliveryIdJBI();

    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataJBI();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки

    // Проходим по массиву searchStrings и находим значения
    const result = searchStrings.map((searchString) => {
      // Проверяем, если searchString есть в словаре deliveryIds
      if (deliveryIds.hasOwnProperty(searchString)) {
        return deliveryIds[searchString]; // Возвращаем ID из словаря
      }

      // Ищем строку по полному соответствию в столбце C
      const foundRow = value.find((arr) => arr[2] === searchString); // Столбец C (индекс 2)

      if (foundRow) {
        return foundRow[10]; // Возвращаем значение из столбца K (индекс 10)
      } else {
        throw new Error(
          `Строка с текстом '${searchString}' не найдена в столбце C.`
        );
      }
    });

    return result; // Возвращаем массив найденных значений
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataJBI:", error);
    throw error;
  }
}

export async function fetchAnalogJBI(key = "stockSheet") {
  try {
    const data = await fetchAllDataJBI(); // Получаем данные из кэша или сервера

    let result = [];
    if (data.hasOwnProperty(key)) {
      const value = data[key].slice(1); // Пропускаем заголовки

      value.forEach((arr) => {
        const itemName = arr[2]; // Столбец C - номенклатура
        const price = arr[4]; // Столбец E - цена
        const density = arr[10]; // Столбец K - плотность
        const analogCategory = arr[11]; // Столбец L - категория аналога
        const thickness = arr[12]; // Столбец M - толщина
        const area = arr[13]; // Столбец N - м²
        const packQuantity = arr[14]; // Столбец O - количество штук в упаковке
        const extraThickness = arr[15]; // Столбец P - дополнительная толщина утеплителя
        const manufacture = arr[1]; // Столбец B - производсто

        if (itemName && price && ((density && analogCategory) || thickness)) {
          result.push({
            itemName,
            price,
            density,
            analogCategory,
            thickness,
            area,
            packQuantity,
            extraThickness,
            manufacture,
          });
        }
      });

      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

//*********************ЖБИ Москва*********************** */
export async function fetchDataJBIMoscow() {
  try {
    // Используем закэшированные данные из fetchAllDataOS
    const data = await fetchAllDataJBIMoscow();
    return data;
  } catch (error) {
    console.error("Ошибка при получении данных из fetchAllDataJBI:", error);
    throw error; // Пробрасываем ошибку дальше
  }
}

export async function fetchDeliveryJBIMoscow(key = "deliverySheet") {
  try {
    const data = await fetchAllDataJBIMoscow(); // Получаем данные из кэша или сервера

    if (data.hasOwnProperty(key)) {
      let value = data[key];
      value = value.slice(1); // Пропускаем заголовки
      let filteredValues = value.map((arr) => arr[0]); // Извлекаем только первый столбец
      return filteredValues;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchDictOfTypesSubsectionsNamesJBIMoscow(
  key = "stockSheet"
) {
  try {
    const data = await fetchAllDataJBIMoscow(); // Получаем данные из кэша или сервера
    let result = {};

    if (data.hasOwnProperty(key)) {
      for (let i = 1; i < data[key].length; i++) {
        // Извлекаем значения из столбцов A, D, C, E и F
        let category = data[key][i][0]; // Столбец A
        let subcategory = data[key][i][3]; // Столбец D
        let itemName = data[key][i][2]; // Столбец C
        let priceCash = data[key][i][4]; // Столбец E (цена нал)
        let priceNonCash = data[key][i][5]; // Столбец F (цена безнал)

        // Проверка на существование данных
        if (category && subcategory && itemName) {
          if (!result[category]) {
            result[category] = {};
          }

          if (!result[category][subcategory]) {
            result[category][subcategory] = [];
          }

          // Добавляем массив с наименованием, ценой нал, ценой безнал
          result[category][subcategory].push([
            itemName,
            priceCash,
            priceNonCash,
          ]);
        }
      }
      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchMassVolumeJBIMoscow(key = "stockSheet") {
  try {
    const data = await fetchAllDataJBIMoscow(); // Получаем данные из кэша или сервера

    if (data.hasOwnProperty(key)) {
      let value = data[key];
      value = value.slice(1); // Пропускаем заголовки

      let result = {};

      value.forEach((arr) => {
        let itemName = arr[2]; // Столбец C - наименование

        // Преобразуем строку с запятой в число с плавающей запятой
        let mass = arr[7] ? parseFloat(arr[7].replace(",", ".")) : 0; // Проверяем, что значение существует
        let volume = arr[8] ? parseFloat(arr[8].replace(",", ".")) : 0;
        let unit = arr[9] || "шт"; // Столбец J - единица измерения (по умолчанию "шт")

        if (itemName && itemName.trim() !== "") {
          result[itemName] = {
            mass: !isNaN(mass) ? mass : 0, // Если значение массы не число, установить 0
            volume: !isNaN(volume) ? volume : 0, // Если значение объема не число, установить 0
            unit: unit.trim(), // Устанавливаем единицу измерения
          };
        }
      });

      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchAnalogJBIMoscow(key = "stockSheet") {
  try {
    const data = await fetchAllDataJBIMoscow(); // Получаем данные из кэша или сервера

    let result = [];
    if (data.hasOwnProperty(key)) {
      const value = data[key].slice(1); // Пропускаем заголовки

      value.forEach((arr) => {
        const itemName = arr[2]; // Столбец C - номенклатура
        const price = arr[4]; // Столбец E - цена
        const density = arr[10]; // Столбец K - плотность
        const analogCategory = arr[11]; // Столбец L - категория аналога
        const thickness = arr[12]; // Столбец M - толщина
        const area = arr[13]; // Столбец N - м²
        const packQuantity = arr[14]; // Столбец O - количество штук в упаковке
        const extraThickness = arr[15]; // Столбец P - дополнительная толщина утеплителя
        const manufacture = arr[1]; // Столбец B - производсто

        if (itemName && price && ((density && analogCategory) || thickness)) {
          result.push({
            itemName,
            price,
            density,
            analogCategory,
            thickness,
            area,
            packQuantity,
            extraThickness,
            manufacture,
          });
        }
      });

      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchDeliveryIdJBIMoscow(key = "deliverySheet") {
  try {
    const data = await fetchAllDataJBIMoscow(); // Получаем данные из кэша или сервера

    if (data.hasOwnProperty(key)) {
      let value = data[key];
      value = value.slice(1); // Пропускаем заголовки

      // Создаем объект (словарь), где ключи из столбца A, а значения из столбца F
      let result = {};
      value.forEach((arr) => {
        let columnA = arr[0]; // Столбец A
        let columnF = arr[5]; // Столбец F

        // Добавляем запись в словарь, если столбец A и столбец F существуют
        if (columnA && columnF) {
          result[columnA] = columnF;
        }
      });

      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}

export async function fetchIdsJBIMoscow(searchStrings, key = "stockSheet") {
  try {
    // Получаем словарь deliveryIds через fetchDeliveryIdOS
    const deliveryIds = await fetchDeliveryIdJBIMoscow();

    // Получаем данные из кэша или сервера через fetchAllDataOS
    const data = await fetchAllDataJBIMoscow();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки

    // Проходим по массиву searchStrings и находим значения
    const result = searchStrings.map((searchString) => {
      // Проверяем, если searchString есть в словаре deliveryIds
      if (deliveryIds.hasOwnProperty(searchString)) {
        return deliveryIds[searchString]; // Возвращаем ID из словаря
      }

      // Ищем строку по полному соответствию в столбце C
      const foundRow = value.find((arr) => arr[2] === searchString); // Столбец C (индекс 2)

      if (foundRow) {
        return foundRow[10]; // Возвращаем значение из столбца K (индекс 10)
      } else {
        throw new Error(
          `Строка с текстом '${searchString}' не найдена в столбце C.`
        );
      }
    });

    return result; // Возвращаем массив найденных значений
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDataJBI:", error);
    throw error;
  }
}

//************************ГАЗОБЕТОН*************************** */

export async function fetchDeliveryGAZ() {
  try {
    // Возвращаем статический массив данных
    const deliveryOptions = ["Шаланда", "Манипулятор 10т", "Манипулятор с прицепом"];
    return deliveryOptions;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchDeliveryGAZ:", error);
    throw error;
  }
}

export async function fetchDictOfTypesSubsectionsNamesGAZ(key = "stockSheet") {
  try {
    const data = await fetchAllDataGAZ(); // Получаем данные из кэша или сервера
    const result = {};

    if (data.hasOwnProperty(key)) {
      for (let i = 1; i < data[key].length; i++) {
        // Извлекаем значения из строки
        const itemName = data[key][i][0]; // Столбец A (наименование)
        const priceCash = data[key][i][4] || 0; // Столбец E (цена нал)
        const priceNonCash = data[key][i][5] || 0; // Столбец F (цена безнал)
        const palletVolume = parseFloat(data[key][i][7]?.replace(",", ".")) || 2; // Столбец H (объем поддона)

        // Генерируем случайные категории и подкатегории
        const category = `Категория`;
        const subcategory = `Подкатегория`;

        // Проверяем наличие наименования товара
        if (itemName) {
          if (!result[category]) {
            result[category] = {};
          }

          if (!result[category][subcategory]) {
            result[category][subcategory] = [];
          }

          // Добавляем массив с наименованием, ценой нал, ценой безнал и объемом поддона
          result[category][subcategory].push([
            itemName,
            parseFloat(priceCash),
            parseFloat(priceNonCash),
            palletVolume, // Добавляем palletVolume
          ]);
        }
      }
      return result;
    } else {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }
  } catch (error) {
    console.error("Ошибка при получении данных:", error);
    throw error;
  }
}


export async function fetchMassVolumeGAZ(key = "stockSheet") {
  try {
    // Получаем данные из кэша или сервера через fetchAllDataGAZ
    const data = await fetchAllDataGAZ();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки

    const result = {};

    value.forEach((arr) => {
      const itemName = arr[0]; // Столбец A - наименование

      // Преобразуем строку с запятой в число с плавающей запятой
      const mass = 0; // Столбец H - масса
      const volume = parseFloat(arr[7]?.replace(",", ".")); // Столбец I - объем
      const unit = arr[3]?.trim() || "м3"; // Столбец D - единица измерения (по умолчанию "m3")

      if (itemName && itemName.trim() !== "") {
        result[itemName] = {
          mass: !isNaN(mass) ? mass : 0, // Если значение массы не число, установить 0
          volume: !isNaN(volume) ? volume : 0, // Если значение объема не число, установить 0
          unit: unit, // Устанавливаем единицу измерения
        };
      }
    });

    return result;
  } catch (error) {
    console.error("Ошибка при обработке данных из fetchAllDatagaz:", error);
    throw error;
  }
}

export async function fetchDataGAZ() {
  try {
    // Используем закэшированные данные из fetchAllDataOS
    const data = await fetchAllDataGAZ();
    return data;
  } catch (error) {
    console.error("Ошибка при получении данных из fetchAllDataJBI:", error);
    throw error; // Пробрасываем ошибку дальше
  }
}


export async function fetchIdsGAZ(searchStrings, key = "stockSheet") {
  try {
    // Задаём статический словарь соответствий
    const deliveryIds = {
      "Манипулятор 10т": "53981",
      "Оптимальный способ": "54001",
      "Шаланда": "54001",
      "Манипулятор с прицепом": "53995",
    };
    // Получаем данные таблицы `STOCK SHEET`
    const data = await fetchAllDataGAZ();

    if (!data.hasOwnProperty(key)) {
      throw new Error(`Ключ '${key}' не найден в данных.`);
    }

    const value = data[key].slice(1); // Пропускаем заголовки

    // Проходим по массиву searchStrings и ищем соответствия
    const result = searchStrings.map((searchString) => {
      // Сначала проверяем в статическом словаре
      if (deliveryIds.hasOwnProperty(searchString)) {
        return deliveryIds[searchString]; // Возвращаем ID из словаря
      }

      // Ищем строку по полному соответствию в столбце A таблицы
      const foundRow = value.find((arr) => arr[0] === searchString); // Столбец A (индекс 0)

      if (foundRow) {
        return foundRow[17]; // Возвращаем значение из столбца R (индекс 17)
      } else {
        throw new Error(
          `Строка с текстом '${searchString}' не найдена ни в словаре, ни в таблице.`
        );
      }
    });

    return result; // Возвращаем массив найденных значений
  } catch (error) {
    console.error("Ошибка при обработке данных:", error);
    throw error;
  }
}

export async function fetchFilteredDataGAZ(names, key = "stockSheet") {
  const allData = await fetchAllDataGAZ(); // Получаем все данные

  // Проверяем, существует ли массив по указанному ключу
  if (!allData[key]) {
    console.error(`Ключ "${key}" отсутствует в данных.`);
    return [];
  }

  // Фильтруем данные по заданным названиям и извлекаем значение из столбца 3
  const filteredData = allData[key]
    .filter(item => names.includes(item[0])) // Сравниваем с данными из столбца 0
    .map(item => ({ columnD: item[3] })); // Извлекаем значение столбца 3

  console.log("Отфильтрованные данные", filteredData);
  return filteredData; // Возвращаем отфильтрованные данные
}

//*******************битрикс*************** */
export const sendDataToBitrix24 = async (dealId, productRows) => {
  const url = "/api/bitrix";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dealId,
        products: productRows,
      }),
    });

    if (!response.ok) {
      throw new Error(`Ошибка сервера: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending data to Bitrix24:", error);
    throw error;
  }
};

export const getAddressFromBitrix24 = async (dealId) => {
  const url = "/api/address"; // Путь к серверному маршруту
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dealId }), // Передаём ID сделки в тело запроса
    });

    if (!response.ok) {
      throw new Error(`Ошибка сервера: ${response.statusText}`);
    }

    const data = await response.json(); // Ожидаем ответ с данными
    return data; // Возвращаем полученный адрес
  } catch (error) {
    console.error("Ошибка при получении адреса из Bitrix24:", error);
    throw error;
  }
};

export const getContactFromBitrix24 = async (contactId) => {
  const url = "/api/contact"; // Путь к серверному маршруту
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ contactId }), // Передаём ID контакта в тело запроса
    });

    if (!response.ok) {
      throw new Error(`Ошибка сервера: ${response.statusText}`);
    }

    const data = await response.json(); // Ожидаем ответ с данными
    return data; // Возвращаем данные о контакте
  } catch (error) {
    console.error("Ошибка при получении данных о контакте из Bitrix24:", error);
    throw error;
  }
};
